// const BASE_URL = "https://myshelta.com/shelterbackend/public/api/";
const BASE_URL = "https://myshelta.com/Testbackend/public/api/";

import store from '../store';

// bodyType = "json" or "FormData"

export default function client({ URL_PATH, method, body, bodyType = "json" }) {
  return new Promise((resolve, reject) => {
    let options = {
      method: method,
      mode: "cors"
    };

    if (bodyType !== "FormData") {
      options.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.getters.getUser.token}`,
        shelta: "web"
      };
    } else {
      options.headers = {
        Authorization: `Bearer ${store.getters.getUser.token}`,
        shelta: "web"
      };
    }

    if (body) {
      options.body = body;
    }

    fetch(`${BASE_URL}${URL_PATH}`, options)
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
