import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "shelterUser",
  storage: window.localStorage
});

const store = new Vuex.Store({
  state: {
    user: {
      id: "",
      passportfile: "",
      passportname: "",
      meansofidfile: ""
    },
    subscription: "",
    isAuthenticated: false,
    isActivated: null,
    activatedStatus: null,
    feedbackCurrentTab: "",
    propertiesActiveLayout: "card",
    leaderboardCurrentTab: "weekly-leaderboard",
    showLeaderboard: false
  },
  mutations: {
    setAuthentication(state, value) {
      state.isAuthenticated = value;
    },

    setActivatedStatus(state, value) {
      state.activatedStatus = value;
    },

    setActivation(state, value) {
      state.isActivated = value;
    },
    setSubscription(state, value) {
      state.subscription = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    clearUser(state) {
      state.user = {};
      // state.subscription = "";
    },
    setUserPassportFile(state, value) {
      state.user.passportfile = value;
    },
    setUserPassportName(state, value) {
      state.user.passportname = value;
    },
    setUserMeansOfIdFile(state, value) {
      state.user.meansofidfile = value;
    },
    setFeedbackCurrentTab(state, value) {
      state.feedbackCurrentTab = value;
    },
    setPropertiesActiveLayout(state, value) {
      state.propertiesActiveLayout = value;
    },
    setLeaderboardCurrentTab(state, value) {
      state.leaderboardCurrentTab = value;
    },
    setShowLeaderboard(state, value) {
      state.showLeaderboard = value;
    }
  },
  actions: {
    setAuthentication({ commit }, value) {
      commit("setAuthentication", value);
    },
    setActivation({ commit }, value) {
      commit("setActivation", value);
    },
    saveActivatedStatus({ commit }, value) {
      commit("setActivatedStatus", value);
    },
    saveSubscription({ commit }, value) {
      commit("setSubscription", value);
    },
    saveUser({ commit }, value) {
      commit("clearUser");
      commit("setUser", value);
      commit("setAuthentication", true);
    },
    clearUser({ commit }) {
      commit("clearUser");
    },
    setUserPassportFile({ commit }, value) {
      commit("setUserPassportFile", value);
    },
    setUserPassportName({ commit }, value) {
      commit("setUserPassportName", value);
    },
    setUserMeansOfIdFile({ commit }, value) {
      commit("setUserMeansOfIdFile", value);
    },
    setFeedbackCurrentTab({ commit }, value) {
      commit("setFeedbackCurrentTab", value);
    },
    setPropertiesActiveLayout({ commit }, value) {
      commit("setPropertiesActiveLayout", value);
    },
    setShowLeaderboard({ commit }, value) {
      commit("setShowLeaderboard", value);
    },
    setLeaderboardCurrentTab({ commit }, value) {
      commit("setLeaderboardCurrentTab", value);
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getActivation(state) {
      return state.isActivated;
    },
    getActivatedStatus(state) {
      return state.activatedStatus;
    },
    getUser(state) {
      return state.user;
    },
    getSubscription(state) {
      return state.subscription;
    },
    getFeedbackCurrentTab(state) {
      return state.feedbackCurrentTab;
    },
    getPropertiesActiveLayout(state) {
      return state.propertiesActiveLayout;
    },
    getLeaderboardTab(state) {
      return state.leaderboardCurrentTab;
    },
    getShowLeaderboard(state) {
      return state.showLeaderboard;
    }
  },
  plugins: [vuexLocal.plugin]
});

export default store;
